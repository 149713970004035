const baseURL = process.env.NEXT_PUBLIC_ANGULAR_PAY_BASE_URL as string;
const oresundsbronURL = process.env.NEXT_PUBLIC_ORESUND_URL as string;
// The links are temporarily hard coded because we are using a different domain for the pages the links takes the user.

type Locale = keyof typeof links;
type LinkKey = keyof typeof links[Locale];

export function getLink(locale: Locale, key: LinkKey): string {
  const path = links[locale][key];

  return `${baseURL}/${locale}/${path}`;
}

export const links = {
  sv: {
    place: 'stallen',
    order: 'bestall',
    help: 'hjalp',
    about: 'om',
    guides: 'hjalp/guide',
    licensPlate: 'hjalp/guide/betaling-med-registreringsnummer',
    payWithBizz: 'hjalp/guide/betalning-med-bizz',
    termsAndConditions: 'om/villkor-och-regler',
    faq: 'hjalp/faq',
    orderContract: 'bestall',
    privacyPolicy: 'om/villkor-och-regler/personuppgiftspolicy',
    cookiePolicy: 'om/villkor-och-regler/cookie-policy',
    customerService: 'hjalp/kontakt',
    whistleBlower: 'hjalp/whistleblower',
    kmToll: 'vagavgift',
    prePaid: 'vagskatt-danmark/ruttbiljett',
  },
  en: {
    place: 'places',
    order: 'order',
    help: 'help',
    about: 'about',
    guides: 'help/guide',
    licensPlate: 'help/guide/payment-by-number-plate',
    payWithBizz: 'help/guide/paying-with-bizz',
    termsAndConditions: 'about/terms-and-conditions',
    faq: 'help/faq',
    orderContract: 'order',
    privacyPolicy: 'about/terms-and-conditions/personal-data-policy',
    cookiePolicy: 'about/terms-and-conditions/cookie-policy',
    customerService: 'help/contact',
    whistleBlower: 'help/whistleblower',
    kmToll: 'roadtoll',
    prePaid: 'roadtax-denmark/routeticket',
  },
  da: {
    place: 'steder',
    order: 'bestil',
    help: 'hjaelp',
    about: 'om',
    guides: 'hjaelp/guide',
    licensPlate: 'hjaelp/guide/betaling-med-nummerplade',
    payWithBizz: 'hjaelp/guide/betaling-med-bizz',
    termsAndConditions: 'om/vilkaar-og-betingelser',
    faq: 'hjaelp/faq',
    orderContract: 'bestil',
    privacyPolicy: 'om/vilkaar-og-betingelser/persondatapolitik',
    cookiePolicy: 'om/vilkaar-og-betingelser/cookie-policy',
    customerService: 'hjaelp/kontakt',
    whistleBlower: 'hjaelp/whistleblower',
    kmToll: 'vejafgift',
    prePaid: 'vejskat-danmark/rutebillet',
  },
  no: {
    place: 'steder',
    order: 'bestill',
    help: 'hjelp',
    about: 'om',
    guides: 'hjelp/guide',
    licensPlate: 'hjelp/guide/betaling-med-registreringsnummer',
    payWithBizz: 'hjelp/guide/betaling-med-bizz',
    termsAndConditions: 'om/vilkar-og-betingelser',
    faq: 'hjelp/faq',
    orderContract: 'bestill',
    privacyPolicy: 'om/vilkar-og-betingelser/personopplysningspolicy',
    cookiePolicy: 'om/vilkar-og-betingelser/cookie-policy',
    customerService: 'hjelp/kontakt',
    whistleBlower: 'hjelp/whistleblowser',
    kmToll: 'veiavgift',
    prePaid: 'veiskatt-danmark/rutebillett',
  },
};

export { baseURL, oresundsbronURL };
