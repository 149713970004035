import { BackendQueryKeys, useTypeSafeQuery } from '@oresundsbron/api';
import { useEffect, useMemo, useState } from 'react';
import { authenticate, useAuth } from '../stores/auth';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';
import { CustomerType } from '@oresundsbron/validators';

export function useIsAuthenticated() {
  const [state, setState] = useState({ loading: true, authenticated: false });
  const [token, setToken] = useState('');
  const { refetch, data } = useTypeSafeQuery(
    BackendQueryKeys.CustomerInformation(),
    ({ Customer }) => Customer.get,
    { enabled: false }
  );
  const router = useRouter();

  const useAuth0 = process.env.NEXT_USE_AUTH0 === 'true';
  const { user, isLoading } = useUser();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    async function handleUser() {
      if (!useAuth0 || !user || isLoading) {
        return;
      }
      const accessTokenResponse = await fetch('/api/auth/access-token');
      if (!accessTokenResponse.ok) {
        router.replace('/api/auth/login');
        return;
      }
      const accessToken = await accessTokenResponse.json();
      if (accessToken) {
        // temporary set as private
        authenticate(accessToken, CustomerType.privateCustomer, true);
        setToken(accessToken);
      }
    }
    handleUser();
  }, [useAuth0, user, isLoading, router]);

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
      setState({ loading: false, authenticated: true });
    } else {
      setState({ loading: false, authenticated: false });
    }
  }, [isAuthenticated, refetch]);

  useEffect(() => {
    if (data) {
      setState({ authenticated: true, loading: false });
      if (useAuth0) {
        authenticate(token, data.customerType, true);
      }
    }
  }, [data, token, useAuth0]);

  return state;
}
